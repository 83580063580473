.modal__img {
    width: 300px;
    background-size: cover;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.form__input:focus {
    border: 1px solid #605e5e !important;
}
@media screen and (max-width: 600px) {
    .modal__img{
        display: none;
    }
}