.footer__overlay::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    top: 0;
    left: 0;
}
.form__input {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #f3f3f3;
    background-color: #ffffff;
    color: #909090;
    font-family: "PT Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-indent: 18px;
}
.form__input:focus {
    outline: none !important;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid #b39571;
    color: #010101;
}

.form__textarea {
    height: 150px;
}

.form__btn {
    height: 50px;
    background-color: #b39571;
    outline: none;
    cursor: pointer;
    padding: 0 20px;
    border: none;
    text-align: center;
    color: #ffffff;
    font-family: "Playfair Display";
    font-size: 25px;
    font-weight: 400;
    text-transform: uppercase;
}