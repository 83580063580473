
.paper__container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
}

/* SLIDE ARROWS */

.slick-prev,.slick-next {
    width: 100px;
    height: 100%;

}
.slick-next::before, .slick-prev::before {
    font-size: 50px;
    color: #cab397;

}

 .slick-prev {
    left: 10px;
    z-index: 10;
}
.slick-next {
    right: 30px;
}

/* SLIDE TITLE  */

.slick-slide .slide__title{
    color: #fff;
    transform: translateY(-200%);
    opacity: 0;
    transition: 1.5s all;
    transition-delay: .5s;
    margin-bottom: 0.9em;
    position: relative;
    padding-left: 100px;
}
.slide__title::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 70px;
    height: 1px;
    background-color: #fff;
}
.slick-active .slide__title{
    transform: translateY(0);
    opacity: 1;
}
/* 
SLIDE CONTENT */

.slick-slide .slide__content {
    transform: translateY(200%);
    opacity: 0;
    transition: 1.5s all;
    transition-delay: .5s;
}

.slick-active .slide__content {
    opacity: 1;
    transform: translateY(0);
}



@media screen and (max-width: 1320px) {
    .slide__container {
        padding-left: 100px !important;
    }
}@media screen and (max-width: 991px) {
    .slide__container {
        padding-left: 24px !important;
    }
}