@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";    

* {
    font-family: 'Montserrat', sans-serif !important;
    
}

th {
    padding: 3px !important;
    text-align: center !important;
    border: #cccccc 1px solid;
    font-size: 13px !important;
}

.page__link {
    color: #b39571;
    font-size: 16px;
    text-decoration: none;
}
.page__link.active {
    color: #303030;
}