.navbar__link {
    text-decoration: none;
    color: #cab397;
    font-family: "PT Sans";
    font-size: 15px;
    font-weight: 700;
   
    position: relative;
    padding: 30px 0;
    cursor: pointer;
    margin-left: 0 !important;
    
}


.navbar__link-url.active {
    color: #303030;
}
.navbar__link:hover {
    color: #303030;
}
.navbar__link:hover .navbar__link-url {
    color: #303030;
}
.navbar__link-url{
    padding: 30px 0;
    text-decoration: none;
    color: #cab397;
    font-family: "PT Sans";
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
}

.navbar__link-dropdown{
    position: relative;
    
}
.submenu {
 
    background: #cab397; /* Заміна змінної на значення або CSS-змінну */
    visibility: hidden;
    opacity: 0;
    min-width: 5rem;
    position: absolute;
    left: 0;
    display: none;
    margin-top: 0px;
    width: 250px;
    padding: 10px 5px;
    top: 100%;
    list-style: none;
}
.submenu__link{
    text-transform: none;
    position: relative;
    padding: 10px 0;
    color: #fff;
    transition: all .3s;
    overflow: hidden;
}
.submenu__link::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #fff;
    bottom: 0;
    left: 0;
    transition: all .3s;
}
.submenu__link:hover {
    color: #000;
}
.submenu__link:hover::before{
    background:#000;
    left: -100px;
}

.navbar__link-dropdown:hover .submenu{
    position: absolute;
    visibility: visible;
    opacity: 1;
    display: block;
    z-index: 99;
}
.navbar__link-dropdown-icon {
    transition: all .3 ease;
}
.navbar__link-dropdown:hover .navbar__link-dropdown-icon{
    transform: rotate(180deg);
    
}



.menu {
    
    padding-right: 20px;
    background-color: #fff;
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100vh;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.6s;
    transform: translate(-100%, 0);
    justify-content: center;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
}

.menu.active {
    transform: translate(0, 0);
    visibility: visible;
}

.mobile__link {
    list-style: none;
    color: #cab397;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 20px;
    transition: color 0.3s ease;
    text-align: right;
    cursor: pointer;
}

.mobile__link.active {
    color: #000;
}

.mobile__link-dropdown {
    position: relative;
}
.mobile__link-dropdown-content {
    display: flex;
    align-items: center; /* Aligns text and arrow on the same line */
    gap: 5px;
    justify-content: flex-end; /* Optional: Adjust spacing between text and arrow */
}
.mobile__submenu {
    max-height: 0; /* Start with max-height at 0 */
    overflow: hidden;
    background: #fff;
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-direction: column;
    transition: max-height 0.4s ease 0.4s, opacity 0.4s ease 0.4s; /* Delayed animation for both */
    padding: 0;
    margin-top: 10px;
    list-style: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional for better visibility */
}

.mobile__submenu.active {
    visibility: visible;
    opacity: 1;
    max-height: 500px; /* Set max-height to a large enough value to cover content */
}

.mobile__submenu__link {
    font-size: 0.9rem; /* Smaller font size for submenu */
    color: #333;
    padding: 10px ;
    border-bottom: 1px solid #e0e0e0; /* Separator line */
    transition: color 0.3s ease;
}

.mobile__submenu__link:hover {
    color: #000; /* Hover effect */
}
.mobile__submenu-icon {
    transition: transform 0.3s ease; /* Only animate the transform property */
}

.mobile__submenu-icon.active {
    transform: rotate(180deg); /* Rotate the icon */
}
    
/* HAMBURGER MENU FOR MOBILE DEVICE */



.hamburger {
    display: none;
}

.hamburger__line{
    position: absolute;
    width: 30px;
    height: 6px;
    background-color: #cab397;
    transition: .3s all;
    overflow: hidden;
}
.hamburger__line:nth-child(1) {
    top: -12px;
}
.hamburger__line:nth-child(3) {
    top: 12px;
}

.hamburger__line.active:nth-child(1) {
    top: 0;
    transform: rotate(45deg);
}
.hamburger__line.active:nth-child(2) {
    top: 0;
    transform: rotate(-45deg);
}
.hamburger__line.active:nth-child(3) {
    display: none;
}




@media screen and (max-width: 992px) {
    .navbar__link {
        display: none !important;
    }
    .navbar {
        min-height: 80px;
        align-items: center;
        justify-content: flex-end;
    }
    .hamburger{ 
        display: block;
    }
    
}